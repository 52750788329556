var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"padded-page-wrapper"},[_c('ModalBase',{attrs:{"name":"otherSpecialVerbiageModal","size":"medium","clickToClose":false,"showClose":true,"title":_vm.title,"loading":_vm.makingApiRequest,"position":"center","bottomButtonSecondary":{
      key: 'cancel',
      label: 'Cancel'
    },"bottomButtonPrimary":_vm.underwritersCode
        ? {
            key: 'enter-code',
            label: 'Submit Underwriter Approval Code'
          }
        : null,"error":_vm.error},on:{"primaryButtonClick":function($event){return _vm.$emit('saveUnderwritersCode', _vm.underwritersCode)},"secondaryButtonClick":function($event){return _vm.$emit('close')},"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"px-4 text-md"},[_c('custom-alert',{attrs:{"dismissible":false,"title":"Please Note","message":_vm.underwriterMsg}}),_c('div',{staticClass:"w-full flex justify-center px-4 py-4"},[_c('TextField',{ref:"field_underwritersCode",staticClass:"flex-grow text-lg",attrs:{"label":"Please enter underwriter code","placeholder":"Underwriter code","required":true,"value":_vm.underwritersCode,"type":"text"},on:{"input":function($event){_vm.underwritersCode = $event}}})],1),(_vm.showOption4Footer)?_c('span',{staticClass:"font-bold atlas-blue"},[_vm._v("Underwriting 888-786-0003, Option 4 ")]):_c('span',{staticClass:"text-red-500"},[_vm._v("Enter underwriter code if all questionable answers have been approved ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }