
import Vue from "vue";
import { quoteMapState } from "@/store/modules/quote";

interface IData {
  message: string;
  error: string;
  value: string;
  underwritersCode: string;
}

export default Vue.extend({
  name: "otherSpecialVerbiageModal",
  components: {
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function
    },
    underwriterMsg: { type: String, default: "" }
  },
  data(): IData {
    return {
      message: "",
      error: "",
      value: "",
      underwritersCode: ""
    };
  },
  methods: {
    onFileUploadError(err: string) {
      this.error = err;
    }
  },
  mounted() {
    if (this.editing && this.editing.approvalCode) {
      this.underwritersCode = this.editing.approvalCode;
    }
  },
  computed: {
    ...quoteMapState(["editing", "makingApiRequest"]),
    creditInformation(): Record<string, any> {
      if (this.editing && this.editing?.creditInformation) {
        return this.editing.creditInformation;
      }
      return {};
    },
    creditScoreIndicatorFlag(): string {
      if (!this.creditInformation || !this.creditInformation?.data) return "";
      const { scoreIndicatorFlag = "D" } = this.creditInformation?.data;
      return scoreIndicatorFlag;
    },
    title(): string {
      if (!this.creditInformation || !this.creditInformation.data) return "";
      const { scoreVerbiage = "" } = this.creditInformation.data;
      if (scoreVerbiage.includes("A NO-HIT FILE IS RETURNED")) {
        return "Unable To Score";
      }
      return scoreVerbiage || "Unable To Score";
    },
    showOption4Footer(): boolean {
      return this.title.toUpperCase().includes("DO NOT BIND");
    }
  },
  watch: {
    showModal: {
      immediate: true,
      handler(show: boolean) {
        if (show) {
          this.$modal.show("otherSpecialVerbiageModal");
        } else {
          this.$modal.hide("otherSpecialVerbiageModal");
        }
      }
    }
  }
});
