
import Vue from "vue";
import {
  quoteApplicationClientForm,
  quoteApplicantForm
} from "@/forms/shared/quote.client_information";

import CoApplicantEditor from "@/views/shared/quotes/RatingApplication/CoApplicantsEditor.vue";
import * as dateFns from "date-fns";

import { cloneDeep, get, isObject, set } from "lodash";
import {
  quoteMapActions,
  quoteMapMutations,
  quoteMapState
} from "@/store/modules/quote";
import { objectDifference, removeEmptyFields } from "@/helpers";
import { quoteApplicantDefaults } from "@/helpers/defaultObjects";

import CreditAndLossSuccessModal from "@/views/shared/quotes/RatingApplication/CreditAndLossSuccessModal.vue";
import SpecialOtherVerbiageModal from "@/views/shared/quotes/RatingApplication/SpecialOtherVerbiageModal.vue";
import ProcessOtherScoreVerbiageModal from "@/views/shared/quotes/RatingApplication/ProcessOtherScoreVerbiageModal.vue";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import OldRatingCustomAlert from "./OldRatingCustomAlert.vue";
import { generateEditCoApplicantPayload } from "@/helpers/coApplicantsHelper";
import UnderwriterInfo from "../Components/UnderwriterInfo.vue";
const noHitFileVerbiage = "REGULAR NO-HIT | A NO-HIT FILE IS RETURNED";
const specialVerbiageFlags = ["D", "2", "N", "DP"];
import { displayXML } from "@/helpers/displayXML";

interface IApplicationInterface {
  showSpecialVerbiageModal: boolean;
  quoteApplicantForm: any;
  showModal: boolean;
  originalFormValues: any;
  formValues: any;
  validationData: any;
  coApplicantRenderKey: number;
  loading: boolean;
  loadingText: string;
  showUnderwritersModal: boolean;
  showCreditAndLossSuccessModal: boolean;
  firstNoHitFileInfoMessage: string;
  errorMessage: string;
  showErrorMessage: boolean;
  taxIdIsValid: boolean;
  errors: string[] | string | undefined;
}
export default Vue.extend({
  name: "application",
  components: {
    CoApplicantEditor,
    SpecialOtherVerbiageModal,
    ProcessOtherScoreVerbiageModal,
    CreditAndLossSuccessModal,
    QuoteSteps: () => import("@/views/shared/quotes/QuoteSteps.vue"),
    CustomAlert,
    OldRatingCustomAlert,
    UnderwriterInfo
  },
  data(): IApplicationInterface {
    return {
      showSpecialVerbiageModal: false,
      quoteApplicantForm: [],
      showModal: false,
      originalFormValues: { ...quoteApplicantDefaults() } as any,
      formValues: {
        ...quoteApplicantDefaults(),
        underwritersCode: ""
      },
      validationData: {},
      coApplicantRenderKey: 0,
      loading: false,
      loadingText: "",
      showUnderwritersModal: false,
      showCreditAndLossSuccessModal: true,
      firstNoHitFileInfoMessage:
        "A NO-HIT FILE was returned. Make sure you entered the correct data then click the next button below to resubmit your data to Verisk.",
      errorMessage: "",
      showErrorMessage: false,
      taxIdIsValid: true,
      errors: ""
    };
  },
  mounted() {
    this.formValues.underwritersCode = this.getUnderWritesCode;
    this.quoteApplicantForm = quoteApplicantForm(this.isMarried);
    this.processCreditScore();
    const state = this.formValues.previousAddress.state;
    this.formValues.previousAddress.state = state ? state : "TX";
  },
  methods: {
    ...quoteMapActions([
      "updateQuoteApplication",
      "step7RunQuoteCreditRating",
      "step7RunQuoteLosses",
      "getSendingOrReceivingXML",
      "saveQuotePropertyFields"
    ]),
    ...quoteMapMutations(["SET_EDIT"]),
    showUnderWritersModal() {
      this.showUnderwritersModal = true;
    },
    async gotoNextStep() {
      if (this.hasLossInformation) {
        this.$router.push(`/quotes/${this.quoteId}/loss-report`);
      } else {
        await this.runLossReport();
      }
    },
    async changeEffectiveDateHandler(): Promise<any> {
      try {
        this.$modal.show("effectiveDateConfirmModal");
      } catch (error) {
        return;
      }
    },
    formFieldChangedHandler(payload: any) {
      if (payload.key === "applicant.ssn") {
        if (isObject(payload.value)) {
          this.taxIdIsValid = payload.value?.isValid;
          set(this.formValues, payload.key, payload.value.value);
        } else if (typeof payload.value === "string") {
          set(this.formValues, payload.key, payload.value);
        }
        return;
      }
      if (payload.key !== "previousAddress") {
        set(
          this.formValues,
          payload.key,
          payload.key == "applicant.lastName" ||
            payload.key == "applicant.middleName" ||
            payload.key == "applicant.firstName"
            ? payload.value.trim().toUpperCase()
            : payload.value
        );
      } else {
        const { fieldKey, value } = payload.value;
        set(this.formValues, `${payload.key}.${fieldKey}`, value);
      }
      this.formValues = { ...this.formValues }; //to force-re-render of form builder
    },
    addCoApplicant(coApplicant: any) {
      coApplicant = removeEmptyFields(coApplicant);
      this.showModal = false;
      this.formValues.coApplicants.push(coApplicant);
      const payload = {
        coApplicants: coApplicant
      };
      this.saveQuoteApplicationCoApplicant(payload);
    },
    async saveUnderwritersCode(underwritersCode: string) {
      set(this.formValues, "underwritersCode", underwritersCode);
      await this.saveQuoteApplication();
      await this.runLossReport();
    },
    deleteCoApplicant(index: number): void {
      const applicantToDelete = this.formValues.coApplicants[index];
      const payload = {
        coApplicants: { _id: applicantToDelete._id, delete: true }
      };
      this.saveQuoteApplicationCoApplicant(payload);
    },
    editCoApplicant({
      index,
      coApplicant
    }: {
      index: number;
      coApplicant: Record<string, any>;
    }): void {
      const existingCoApplicants = this.formValues.coApplicants;

      const payload = generateEditCoApplicantPayload(
        { index, coApplicant },
        existingCoApplicants
      );
      this.saveQuoteApplicationCoApplicant(payload);
    },
    async saveQuoteApplicationData(key: string) {
      if (key === "save") {
        await this.saveQuoteApplication();
        return;
      }
      this.showErrorMessage = false;

      if (key === "cancel") {
        return this.$router.push(`/quotes`);
      } else if (key === "viewSendingXML") {
        this.sendingXMLHandler("VS");
      } else if (key === "viewResponseXML") {
        this.sendingXMLHandler("VR");
      } else if (key === "next") {
        return this.$router.push(`/quotes/${this.quoteId}/loss-report`);
      } else if (key === "run-loss-and-credit") {
        return this.runCreditAndLoss();
      } else if (key === "enter-underwriters-code") {
        this.showSpecialVerbiageModal = true;
        return;
      }
      await this.saveQuoteApplication();
    },
    async runCreditAndLoss() {
      const creditSucceeded = await this.runCreditScore();
      if (creditSucceeded) {
        await this.runLossReport();
      }
    },
    validateFieldToBeUpdated() {
      const isValid =
        this.validationData.fieldsWithErrors.every((field: any) => {
          return !get(this.updatedFields, field);
        }) &&
        (!get(this.updatedFields, "applicant.ssn") || this.taxIdIsValid);
      return isValid;
    },
    async saveQuoteApplicationCoApplicant(updates: any = null): Promise<void> {
      if (!this.canSaveApplicationFields) {
        return;
      }
      try {
        let response;
        const quoteId = this.$route.params.quoteId;
        this.loading = true;
        if (updates) {
          response = await this.updateQuoteApplication({
            quoteId,
            updates
          });
        }
        this.SET_EDIT(response.data);
        this.showErrorMessage = false;
      } catch (error) {
        const errorMessage = this.getErrorMessage(error as any);
        const genericErrorMessage =
          "There was an error saving your responses. Try again later.";
        this.errorMessage = errorMessage || genericErrorMessage;
        this.showErrorMessage = true;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async saveQuoteApplication(): Promise<void> {
      if (!this.canSaveApplicationFields) {
        return;
      }
      try {
        const quoteId = this.$route.params.quoteId;
        this.loading = true;
        if (Object.keys(this.updatedFields).length === 0) {
          return;
        }
        const response = await this.updateQuoteApplication({
          quoteId,
          updates: { ...this.updatedFields }
        });

        if (this.updatedFields.approvalCode) {
          await this.saveQuotePropertyFields({
            id: this.$route.params.quoteId,
            payload: {
              approvalCode: this.updatedFields.approvalCode,
              hasUnderwriterApproved: this.updatedFields.hasUnderwriterApproved
            }
          });
        }
        this.SET_EDIT(response.data);
      } catch (error) {
        const errorMessage = this.getErrorMessage(error as any);
        const genericErrorMessage =
          "There was an error saving your responses. Try again later.";
        this.errorMessage = errorMessage || genericErrorMessage;
        this.showErrorMessage = true;
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    async runCreditScore(): Promise<boolean> {
      try {
        if (this.ageOfApplicant < 18) {
          this.showAgeRestrictionAlert();
          return false;
        }
        this.loading = true;
        this.showErrorMessage = false;
        const id = this.$route.params.quoteId;
        this.loadingText = "Getting credit information";
        const response = await this.step7RunQuoteCreditRating({
          id
        });
        if (response && response?.data) {
          const creditData = response.data;
          this.SET_EDIT(creditData);

          const scoreIndicatorFlag = this.scoreIndicatorFlag(creditData);

          if (["A", "3"].includes(scoreIndicatorFlag)) {
            return true;
          } else {
            if (
              this.scoreVerbiage(creditData) === noHitFileVerbiage &&
              this.numberOfRequests(creditData) === 1
            ) {
              this.$modal.show("firstNoHitFileInfoModal");
            } else {
              if (specialVerbiageFlags.includes(scoreIndicatorFlag)) {
                this.showSpecialVerbiageModal = true;
              } else {
                this.showUnderWritersModal();
              }
            }
          }
        } else {
          this.errorMessage = "Error getting credit info. Try again later.";
          this.showErrorMessage = true;
        }
      } catch (error) {
        const errorMessage = this.getErrorMessage(error as any);
        const genericErrorMessage =
          "There was a problem processing your credit information";
        this.errorMessage = errorMessage || genericErrorMessage;
        this.showErrorMessage = true;
      } finally {
        this.loadingText = "";
        this.loading = false;
      }
      return false;
    },
    async runLossReport() {
      try {
        if (!this.hasLossInformation) {
          this.loading = true;
          this.loadingText = "Checking for existing loss information";
          if (this.editing.lossReports && this.editing.lossReports.length > 0) {
            this.loadingText = "Existing loss information found. proceeding";
          } else {
            this.loadingText = "Getting loss information now.";
            await this.step7RunQuoteLosses({
              id: this.quoteId
            });
          }
        }
        this.$modal.show("creditAndLossSuccessModal");
      } catch (error) {
        const errorMessage = this.getErrorMessage(error as any);
        const genericErrorMessage =
          "There was a problem generating your loss report";
        this.errorMessage = errorMessage || genericErrorMessage;
        this.showErrorMessage = true;
      } finally {
        this.loadingText = "";
        this.loading = false;
      }
    },
    showAgeRestrictionAlert() {
      const message = "Applicant must be 18 years or older to proceed";
      this.$alert(message, "Invalid Date of birth", {
        confirmButtonText: "OK",
        callback: () => {}
      });
    },
    async processCreditScore() {
      //decides whether to show a modal and which kind of modal
      if (
        !this.editing ||
        !this.editing?.creditInformation ||
        !this.editing?.creditInformation?.numberOfRequests
      ) {
        return;
      }

      const creditInformation = this.creditInformation(this.editing);

      const scoreIndicatorFlag = this.scoreIndicatorFlag(this.editing);

      const scoreVerbiage = this.scoreVerbiage(this.editing);

      if (scoreIndicatorFlag === "A") {
        this.$modal.show("creditAndLossSuccessModal");
      } else if (
        scoreVerbiage.trim() === noHitFileVerbiage &&
        creditInformation.numberOfRequests === 1
      ) {
        this.$modal.show("firstNoHitFileInfoModal");
      } else {
        if (specialVerbiageFlags.includes(scoreIndicatorFlag)) {
          this.showSpecialVerbiageModal = true;
        }
      }
    },
    doErrorCheck(
      error: any,
      genericErrorMessage = "There was an error processing your request. Try again later."
    ) {
      const errorMessage = this.getErrorMessage(error as any);
      this.errorMessage = errorMessage || genericErrorMessage;
      this.showErrorMessage = true;
      this.$bugSnagClient.notify(error);
    },
    getErrorMessage(error: { message: string | any[] }): string {
      return Array.isArray(error.message)
        ? error.message.join("\n")
        : error.message;
    },
    async sendingXMLHandler(responseType: "VS" | "VR"): Promise<void> {
      this.loading = true;
      try {
        const response = await this.getSendingOrReceivingXML({
          responseType,
          rateId: this.editing?.ratingSelected,
          quoteId: this.quoteId,
          type: "creditLoss"
        });
        const { rawXML } = response.data;
        displayXML(rawXML);
      } catch (error) {
        this.errors = (error as Error).message || "";
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    },
    scoreIndicatorFlag(creditData: any): string {
      const creditInformation = this.creditInformation(creditData);
      return get(creditInformation, "data.scoreIndicatorFlag", "");
    },
    scoreVerbiage(creditData: any): string {
      const creditInformation = this.creditInformation(creditData);
      return get(creditInformation, "data.scoreVerbiage", "").trim();
    },
    numberOfRequests(creditData: any) {
      const creditInformation = this.creditInformation(creditData);
      return get(creditInformation, "numberOfRequests", 0);
    },
    creditInformation(creditData: any) {
      return get(creditData, "creditInformation", {});
    }
  },
  computed: {
    ...quoteMapState(["editing", "makingApiRequest"]),
    underwriterMsg(): string {
      return `Contact your Underwriter for underwriter code @ ${this.underwriterPhone}`;
    },
    underwriterPhone(): string {
      let phone =
        this.editing?.quoteUnderwriter?.phone?.primary ||
        "888-786-0003, option #4 ";

      return phone;
      // " .Then enter underwriter code in the highlighted input field below"
    },
    isMarried(): boolean {
      return get(this.formValues, "applicant.maritalStatus", "") === "married";
    },
    applicationIsInValid(): boolean {
      return (
        this.numberOfRequests(this.editing) > 0 &&
        !this.quoteApplication?.isValid
      );
    },
    applicationValidationFields(): string[] {
      return [
        "First Name",
        "Last Name",
        "Date Of Birth",
        "Marital Status",
        "House Number",
        "Street Name",
        "City",
        "State",
        "Zip Code"
      ];
    },
    getUnderWritesCode(): number {
      return this.editing.approvalCode;
    },
    coApplicantIsRequired(): boolean {
      return this.isMarried && !this.coApplicantsExist;
    },
    coApplicantsExist(): boolean {
      return !!get(this.formValues, "coApplicants", []).length;
    },
    previousAddressIsRequired(): boolean {
      return (
        this.editing.policyType === "D" &&
        !(
          this.formValues.previousAddress.houseNumber &&
          this.formValues.previousAddress.streetName &&
          this.formValues.previousAddress.state &&
          this.formValues.previousAddress.city &&
          this.formValues.previousAddress.zipCode
        )
      );
    },
    updatedFields(): any {
      return objectDifference(this.formValues, this.originalFormValues, [
        "applicant.ssn",
        "coApplicants.ssn"
      ]);
    },
    topActionButtonPrimary(): {
      text: string;
      key: string;
      loading?: boolean;
      disabled?: boolean;
    } | null {
      const blacklistedAgents = ["frmk", "cll-frmk", "aga-frmk"];
      const agentCode = get(this.editing, "agentCode", "");
      const creditInformation = get(this.editing, "creditInformation", {});
      const data = get(creditInformation, "data", {});
      const { scoreVerbiage = "" } = data;
      if (
        this.editing &&
        this.editing.quoteApplication &&
        this.editing.quoteApplication?.isValid
      ) {
        if (this.$getCurrentUser && this.$getCurrentUser.role !== "admin") {
          if (
            this.hasValidUnderwritersCode &&
            this.editing.ratingValidations.hasValidLossInformation &&
            this.editing.ratingValidations.hasCreditRating
          ) {
            return null;
          }
        }
        if (
          (this.coApplicantIsRequired && !this.coApplicantsExist) ||
          this.previousAddressIsRequired
        ) {
          return null;
        }
        if (Object.keys(this.updatedFields).length > 0) {
          return {
            text: "Save",
            key: "save",
            disabled: !this.validationData.formIsValid || !this.taxIdIsValid,
            loading: this.makingApiRequest || this.loading
          };
        }
        if (
          this.numberOfRequests(this.editing) &&
          !blacklistedAgents.includes(agentCode.toLowerCase()) &&
          !this.$isCurrentUserAdmin &&
          scoreVerbiage !== noHitFileVerbiage
        ) {
          return null;
        }
        return {
          text: "Run Loss & Credit",
          key: "run-loss-and-credit",
          disabled: !this.validationData.formIsValid,
          loading: this.makingApiRequest || this.loading
        };
      }
      return null;
    },
    topActionButtonSecondary(): any {
      const subItems = [
        {
          title: "Cancel",
          command: "cancel"
        }
      ];
      const baseButtons = {
        text: "Actions",
        key: "actions",
        subItems
      };
      if (this.$isCurrentUserAdmin && this.validationData.formIsValid) {
        subItems.push(
          {
            title: "Sending XML",
            command: "viewSendingXML"
          },
          {
            title: "Response XML",
            command: "viewResponseXML"
          }
        );
      }
      if (
        this.editing &&
        this.hasValidCreditScore &&
        this.hasLossInformation &&
        this.validationData.formIsValid
      ) {
        subItems.push({
          title: "Next",
          command: "next"
        });
      }
      if (this.hasToProvideUnderwritersCode) {
        subItems.push({
          title: "Enter underwriters code",
          command: "enter-underwriters-code"
        });
      }
      subItems.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      return baseButtons;
    },
    hasLossInformation(): boolean {
      return (
        this.editing &&
        this.editing?.lossReport &&
        this.editing?.lossReport?.numberOfRequests > 0
      );
    },
    ageOfApplicant(): number {
      const dateOfBirth = get(this.formValues, "applicant.dateOfBirth");
      const age = dateFns.differenceInYears(new Date(), new Date(dateOfBirth));
      return age;
    },
    quoteId(): string {
      return this.$route.params.quoteId;
    },
    quoteApplication(): any | undefined {
      if (this.editing) {
        return this.editing.quoteApplication;
      }
      return undefined;
    },
    hasValidCreditScore(): boolean {
      const creditInformation = this.creditInformation(this.editing);
      const data = get(creditInformation, "data", {});
      if (
        creditInformation &&
        Object.keys(creditInformation).length &&
        data &&
        Object.keys(data).length
      ) {
        return this.scoreIndicatorFlag(this.editing) === "A";
      } else {
        return this.hasValidUnderwritersCode;
      }
    },
    hasToProvideUnderwritersCode(): boolean {
      //we need a non null credit rating
      if (this.editing && this.editing.creditInformation) {
        return !this.hasValidCreditScore;
      }
      if (this.applicationHasChangedSinceLastCreditRating) return false;
      return false;
    },
    hasValidUnderwritersCode(): boolean {
      /**
       * The issue here is that we are supposed to not show the modal if a valid one exists.
       * TODO: figure out the validation for underwriters code and do it.
       */
      if (this.quoteApplication) {
        return !!this.quoteApplication.underwritersCode;
      }
      return false;
    },
    hasValidLossInformation(): boolean {
      if (this.editing && this.editing.ratingValidations) {
        return this.editing.ratingValidations.hasValidLossInformation;
      }
      return false;
    },
    applicationHasChangedSinceLastCreditRating(): boolean {
      if (
        this.editing &&
        this.editing.creditInformation &&
        this.editing.quoteApplication
      ) {
        const {
          creditInformation = null,
          quoteApplication = null
        } = this.editing;
        const { modifiedOn } = creditInformation;
        if (modifiedOn) {
          return dateFns.isAfter(
            new Date(quoteApplication.modifiedOn),
            new Date(modifiedOn)
          );
        }
      }
      return false;
    },
    schemas(): Array<any> {
      if (!this.editing) return [];
      return quoteApplicationClientForm(
        this.editing.policyType,
        this.quoteHasBeenSubmitted
      );
    },
    quoteHasBeenSubmitted(): boolean {
      return !!(
        this.editing &&
        this.editing.status === "submitted" &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasPolicyNumber
      );
    },
    quoteHasBeenDeleted(): boolean {
      return !!(this.editing && this.editing.deleted);
    },
    hasRatings(): boolean {
      return (
        this.editing &&
        this.editing.ratingValidations &&
        this.editing.ratingValidations.hasSelectedRating
      );
    },
    canSaveApplicationFields(): boolean {
      return (
        (this.formValues.applicant.firstName &&
          this.formValues.applicant.firstName.length <= 20) ||
        (this.formValues.applicant.lastName &&
          this.formValues.applicant.lastName.length <= 26) ||
        this.validateFieldToBeUpdated() ||
        !this.loading
      );
    }
  },
  watch: {
    editing: {
      deep: true,
      immediate: true,
      handler(value: Record<string, any>) {
        this.formValues = {
          ...this.formValues,
          ...(cloneDeep(get(value, "quoteApplication")) || {}),
          riskAddress: get(value, "riskAddress", {}),
          effectiveDate: get(value, "effectiveDate")
        };
        this.originalFormValues = {
          ...quoteApplicantDefaults(),
          ...cloneDeep(this.formValues)
        };
      }
    }
  }
});
