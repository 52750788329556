
import Vue from "vue";
import { quoteMapState } from "@/store/modules/quote";

interface IData {
  message: string;
  error: string;
  value: string;
  underwritersCode: string;
}

export default Vue.extend({
  name: "SpecialOtherVerbiageModal",
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function
    },
    underwriterMsg: { type: String, default: "" }
  },
  data(): IData {
    return {
      message: "",
      error: "",
      value: "",
      underwritersCode: ""
    };
  },
  methods: {
    primaryButtonClickHandler() {
      this.$emit("showUnderwritersModal");
      this.$emit("close");
    }
  },
  computed: {
    ...quoteMapState(["editing", "makingApiRequest"]),
    creditInformation(): Record<string, any> {
      if (this.editing && this.editing.creditInformation) {
        return this.editing.creditInformation;
      }
      return {};
    },
    creditScoreIndicatorFlag(): string {
      if (!this.creditInformation || !this.creditInformation?.data) return "";
      const { scoreIndicatorFlag = "D" } = this.creditInformation?.data;
      return scoreIndicatorFlag;
    },
    title(): string {
      const defaultTitle = "A NO-HIT FILE WAS RETURNED";
      if (!this.creditInformation || !this.creditInformation.data)
        return defaultTitle;
      const { scoreVerbiage = defaultTitle } = this.creditInformation.data;
      if (scoreVerbiage.includes("A NO-HIT") || !scoreVerbiage) {
        return "Unable To Score";
      }
      return scoreVerbiage;
    }
  },
  watch: {
    showModal: {
      immediate: true,
      handler(show: boolean) {
        if (show) {
          this.$modal.show("specialOtherVerbiageModal");
        } else {
          this.$modal.hide("specialOtherVerbiageModal");
        }
      }
    }
  }
});
