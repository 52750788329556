
import Vue from "vue";
import { quoteMapState } from "@/store/modules/quote";
import pluralize from "pluralize";
import { get } from "lodash";

interface IData {
  message: string;
  error: string;
  value: string;
  underwritersCode: string;
}

export default Vue.extend({
  name: "creditAndLossSuccessModal",
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function
    }
  },
  data(): IData {
    return {
      message: "",
      error: "",
      value: "",
      underwritersCode: ""
    };
  },
  methods: {
    onFileUploadError(err: string) {
      this.error = err;
    }
  },
  computed: {
    ...quoteMapState(["editing", "makingApiRequest"]),
    creditInformation(): Record<string, any> {
      if (this.editing) return this.editing?.creditInformation;
      return {};
    },
    creditScoreIndicatorFlag(): string {
      if (!this.creditInformation) return "";
      const { scoreIndicatorFlag = "D" } = this.creditInformation?.data;
      return scoreIndicatorFlag;
    },
    creditScoreVerbiage(): string {
      if (this.creditInformation && this.creditInformation?.data) {
        const { scoreVerbiage = "" } = this.creditInformation?.data;
        return scoreVerbiage;
      }
      return "";
    },
    title(): string {
      return "Credit and Loss Report Success";
    },
    showOption4Footer(): boolean {
      return this.title.toUpperCase().includes("DO NOT BIND");
    },
    numberOfLosses(): number {
      const lossInformation = get(
        this.editing,
        "lossReport.lossInformation",
        []
      );
      return lossInformation.length;
    },
    lossInformation(): string {
      if (this.numberOfLosses === 0) return "No loss info";
      const lossWord = pluralize("loss", this.numberOfLosses);
      return `${this.numberOfLosses} ${lossWord} received.`;
    }
  },
  watch: {
    showModal: {
      immediate: true,
      handler(show: boolean) {
        if (show) {
          this.$modal.show("creditAndLossSuccessModal");
        } else {
          this.$modal.hide("creditAndLossSuccessModal");
        }
      }
    }
  }
});
